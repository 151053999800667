import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import {
  getMeetingRoomLabel,
  getMeetingTypeLabel,
  getMeetingEntruseInLabel,
} from '../../Utils/SelectOptionLabelUtil';
import ExportHelper from '../../TbaExportHelper';

const EXPORT_COLLECTION = 'Meeting';
const EXPORT_TITLE_DEFAULT = '會議管理匯出';
const EXPORT_FIELDS = [
  {key: 'MTNG_TYPE', name: '會議類別'},
  {key: 'MTNG_NAME', name: '會議名稱'},
  {key: 'ENTRUST_IND', name: '委派會議'},
  {key: 'morale_list', name: '相關倫理風紀'},
  {key: 'international_exchange', name: '相關國際交流'},
  {key: 'committee', name: '相關團體'},
  {key: 'product', name: '相關課程/活動'},
  {key: 'organizer_person', name: '主辦人'},
  {key: 'organizer', name: '主協辦單位'},
  {key: 'EFF_DATE', name: '會議日期'},
  {key: 'START_TIME', name: '開始時間'},
  {key: 'END_TIME', name: '結束時間'},
  {key: 'room', name: '會議室'},
  {key: 'equipment', name: '設備'},
  {key: 'NOTE', name: '備註'},
  {key: 'DESCRIPTION', name: '會議說明'},
  {key: 'users', name: '出席人數'},
  {key: 'CEXECUTE', name: '執行情形'},
];

function transformQuery(formData) {
  const {MTNG_TYPE, MTNG_NAME, EFF_DATE_START, EFF_DATE_END} = formData;
  const query = {
    archived: {$ne: true},
  };

  if (!!MTNG_TYPE) {
    query['MTNG_TYPE'] = MTNG_TYPE;
  }

  if (!!MTNG_NAME) {
    query['MTNG_NAME'] = {$regex: MTNG_NAME};
  }

  if (!!EFF_DATE_START) {
    query['EFF_DATE'] = {$gte: EFF_DATE_START};
  }

  if (!!EFF_DATE_END) {
    if (query.EFF_DATE) {
      query['EFF_DATE'] = {...query.EFF_DATE, $lt: EFF_DATE_END};
    } else {
      query['EFF_DATE'] = {$lt: EFF_DATE_END};
    }
  }
  return query;
}

function transformProjection(formData) {
  const projection = {};
  return projection;
}

function generateExportFields(selectAllFields, fields) {
  return selectAllFields
    ? EXPORT_FIELDS
    : EXPORT_FIELDS.filter(
        (obj) => fields.findIndex((key) => key === obj.key) > -1,
      );
}

function AdminMeetingExport(props) {
  return (
    <Wrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: EXPORT_TITLE_DEFAULT,
            },
            MTNG_NAME: {
              type: 'string',
              title: '會議名稱',
            },
            MTNG_TYPE: {
              type: 'string',
              title: '類型',
              enum: ['INNER', 'OUTER', 'GSM', 'BM', 'SV', 'MD', 'BOSM'],
              enumNames: [
                '內部',
                '外部',
                '會員大會',
                '理事會',
                '監事會',
                '常務理事會議',
                '理監事聯席會議',
              ],
            },
            EFF_DATE_START: {
              type: 'string',
              title: '會議日期區間(起)',
              format: 'date',
            },
            EFF_DATE_END: {
              type: 'string',
              title: '會議日期區間(迄)',
              format: 'date',
            },
            selectAllFields: {
              type: 'boolean',
              title: '匯出欄位全選',
              enum: [true, false],
              enumNames: ['是', '否'],
              default: true,
            },
          },
          dependencies: {
            selectAllFields: {
              oneOf: [
                {
                  required: ['fields'],
                  properties: {
                    selectAllFields: {enum: [false]},
                    fields: {
                      type: 'array',
                      title: '匯出欄位選擇',
                      items: {
                        type: 'string',
                        enum: EXPORT_FIELDS.map((field) => field.key),
                        enumNames: EXPORT_FIELDS.map((field) => field.name),
                      },
                      uniqueItems: true,
                    },
                  },
                },
              ],
            },
          },
        }}
        uiSchema={{
          EFF_DATE_START: {'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01'},
          EFF_DATE_END: {'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01'},
        }}
        onSubmit={(formData) => {
          const {selectAllFields, fields} = formData;

          let query = transformQuery(formData);
          let projection = transformProjection(formData);
          let selectedFields = generateExportFields(selectAllFields, fields);

          let excelProps = {
            collection: EXPORT_COLLECTION,
            filename: `${formData.title || EXPORT_TITLE_DEFAULT}.xlsx`,
            query,
            projection,
            recordsToAoa: async (records) => {
              try {
                const helper = new ExportHelper({
                  committeeIds: records
                    .filter((r) => r.committee)
                    .map((r) => r.committee),
                  intExchIds: records
                    .filter((r) => r.international_exchange)
                    .map((r) => r.international_exchange),
                  moraleIds: (() => {
                    const ids = [];
                    const morale_list = records.map((r) => r.morale_list);

                    for (const arrayMorale of morale_list) {
                      if (
                        Array.isArray(arrayMorale) &&
                        arrayMorale.length > 0
                      ) {
                        for (const itemMorale of arrayMorale) {
                          ids.push(itemMorale.morale);
                        }
                      }
                    }

                    return ids;
                  })(),
                });

                await helper.fetchAll();

                return [
                  [...selectedFields.map((f) => f.name)],
                  ...records.map((r, index) => {
                    const returnValues = selectedFields.map((f) => {
                      if (f.key === 'MTNG_TYPE') {
                        return getMeetingTypeLabel(r[f.key]);
                      } else if (f.key === 'ENTRUST_IND') {
                        return getMeetingEntruseInLabel(r[f.key]);
                      } else if (f.key === 'room') {
                        return getMeetingRoomLabel(r[f.key], r['room_note']);
                      } else if (f.key === 'users') {
                        return Array.isArray(r[f.key]) ? r[f.key].length : 0;
                      } else if (f.key === 'organizer_person') {
                        return helper.getUser(r[f.key]).name || '';
                      } else if (f.key === 'product') {
                        return helper.getProduct(r[f.key]).name || '';
                      } else if (f.key === 'committee') {
                        return helper.getCommittee(r[f.key]).NAME || '';
                      } else if (f.key === 'international_exchange') {
                        return helper.getIntExch(r[f.key]).title || '';
                      } else if (f.key === 'morale_list') {
                        const moraleIds = (r['morale_list'] || []).map(
                          (item) => item.morale,
                        );

                        return moraleIds.length > 0
                          ? moraleIds.map(
                              (id, index) =>
                                `${helper.getMorale(id).case_number}${
                                  index + 1 === moraleIds.length ? '' : '、'
                                }`,
                            )
                          : '';
                      }

                      return r[f.key];
                    });

                    return [...returnValues];
                  }),
                ];
              } catch (err) {
                console.warn(err);
              }
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminMeetingExport;
