import React from 'react';
import styled from 'styled-components';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';

const isOpenLabel = (isOpen) => {
  return isOpen === 0 ? '否' : '是';
};

function AdminLibraryExport(props) {
  return (
    <Wrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: '館藏匯出',
            },
          },
        }}
        uiSchema={{}}
        onSubmit={(formData) => {
          const query = {};
          const projection = {
            name: 1,
            author: 1,
            publisher: 1,
            cabinwt_number: 1,
            reel: 1,
            period: 1,
            isbn: 1,
            ismn: 1,
            gpn: 1,
            Start_Time: 1,
            End_TIme: 1,
            is_open: 1,
          };

          const excelProps = {
            collection: 'Library_Info',
            filename: `${formData.title || '館藏匯出'}.xlsx`,
            query,
            projection,
            recordsToAoa: async (records) => {
              return [
                [
                  '編號',
                  '書名',
                  '作者',
                  '出版者',
                  '櫃號',
                  '卷',
                  '期',
                  'ISBN',
                  'ISSN',
                  'ISRC',
                  'ISMN',
                  'GPN',
                  '上架日期',
                  '下架日期',
                  '是否顯示',
                ],
                ...records.map((record, index) => [
                  `${index + 1}`,
                  record.name,
                  record.author,
                  record.publisher,
                  record.cabinwt_number,
                  record.reel,
                  record.period,
                  record.isbn,
                  record.issn,
                  record.isrc,
                  record.ismn,
                  record.gpn,
                  record.Start_Time,
                  record.End_Time,
                  isOpenLabel(record.is_open),
                ]),
              ];
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminLibraryExport;
